<template>
  <div class="app-container">
    <CrudTable entity="MonitoredProcess" :columns="columns" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        'name',
        'description',
        {
          field: 'createdAt',
          label: 'monitoredProcess.createdAt',
          type: 'dateTime',
          filter: { type: 'none' }
        },
        {
          field: 'lastRunAt',
          label: 'monitoredProcess.lastRunAt',
          type: 'dateTime',
          filter: { type: 'none' }
        },
        {
          field: 'lastError',
          label: 'monitoredProcess.lastError'
        },
        {
          field: 'nextRunAt',
          label: 'monitoredProcess.nextRunAt',
          type: 'dateTime',
          filter: { type: 'none' }
        }
      ]
    };
  }
};
</script>

<style scoped></style>
